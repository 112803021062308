"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalaryBankingDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var update_advanced_user_details_1 = require("../../../api/calls/user/update_advanced_user_details");
var main_1 = require("../../../reducers/slices/main");
var get_user_details_1 = require("../../../api/calls/user/get_user_details");
var SalaryBankingDetailsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)(""), accountNumberMessage = _a[0], setAccountNumberMessage = _a[1];
    var _b = (0, react_1.useState)(""), accountTypeMessage = _b[0], setAccountTypeMessage = _b[1];
    var _c = (0, react_1.useState)(""), branchCodeMessage = _c[0], setBranchCodeMessage = _c[1];
    var _d = (0, react_1.useState)(""), bankMessage = _d[0], setBankMessage = _d[1];
    var _e = (0, react_1.useState)(""), accountNumber = _e[0], setAccountNumber = _e[1];
    var _f = (0, react_1.useState)(""), accountType = _f[0], setAccountType = _f[1];
    var _g = (0, react_1.useState)(""), branchCode = _g[0], setBranchCode = _g[1];
    var _h = (0, react_1.useState)(""), bank = _h[0], setBank = _h[1];
    //
    var _j = (0, react_1.useState)(false), isSaving = _j[0], setIsSaving = _j[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        if (user.uid) {
            (0, get_user_details_1.get_user_details)().then(function (response) {
                var _a, _b, _c, _d, _e, _f;
                if (response.success) {
                    setAccountNumber((_b = (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.salary_bank) === null || _b === void 0 ? void 0 : _b.account_number);
                    setAccountType((_d = (_c = response === null || response === void 0 ? void 0 : response.content) === null || _c === void 0 ? void 0 : _c.salary_bank) === null || _d === void 0 ? void 0 : _d.account_type);
                    // setBranchCode(response?.content?.salary_bank?.branch);
                    setBank((_f = (_e = response === null || response === void 0 ? void 0 : response.content) === null || _e === void 0 ? void 0 : _e.salary_bank) === null || _f === void 0 ? void 0 : _f.bank);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
        //
    }, [user]);
    var saveSalaryBanking = function () {
        if (validateDetails()) {
            setIsSaving(true);
            var salary_bank = {
                date_updated: new Date(),
                account_number: accountNumber,
                account_type: accountType,
                bank: bank,
                //  branch: branchCode,
            };
            (0, update_advanced_user_details_1.update_advanced_user_details)({
                salary_bank: salary_bank,
            }).then(function (response) {
                setIsSaving(false);
                if (response.success) {
                    navigate("/employment-details/complete");
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    };
    var validateDetails = function () {
        var messageCount = 0;
        // if (!branchCode) {
        //   messageCount++;
        //   setBranchCodeMessage("Branch code is required.");
        // }
        // if (!bank) {
        //   messageCount++;
        //   setBankMessage("Select bank.");
        // }
        // if (!accountNumber) {
        //   messageCount++;
        //   setAccountNumberMessage("Account number is required.");
        // }
        // if (!accountType) {
        //   messageCount++;
        //   setAccountTypeMessage("Relationship is required.");
        // }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "#",
                        title: "Employment Details",
                    },
                    {
                        title: "Salary Banking Details",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Salary Banking Details", textColor: "text-black", bold: true })),
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Bank Windhoek", value: "Bank Windhoek" },
                            { label: "First National Bank", value: "First National Bank" },
                            { label: "Nedbank", value: "Nedbank" },
                            { label: "Standard Bank", value: "Standard Bank" },
                            { label: "Trutsco Bank", value: "Trutsco Bank" },
                            { label: "Banco Atlantico", value: "Banco Atlantico" },
                            { label: "Bank BIC Namibia", value: "Bank BIC Namibia" },
                            { label: "Letshego Bank Namibia", value: "Letshego Bank Namibia" },
                        ], errorTextColor: "text-red-500", label: "Bank", size: "small", value: bank, errorText: bankMessage, onChange: function (v) {
                            setBank(v);
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Account Number", value: accountNumber, size: "small", errorText: accountNumberMessage, onChange: function (v) { return setAccountNumber(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Cheque", value: "Cheque" },
                            { label: "Savings", value: "Savings" },
                        ], errorTextColor: "text-red-500", label: "Account Type", size: "small", value: accountType, errorText: accountTypeMessage, onChange: function (v) {
                            setAccountType(v);
                        } })),
                react_1.default.createElement("div", { className: "flex justify-center gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", backgroundColor: "bg-primary", size: "small", loading: isSaving, startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            navigate("/employment-details/paypoint-data");
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save & Proceed", loading: isSaving, backgroundColor: "bg-secondary", size: "small", endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                            saveSalaryBanking();
                        } }))))));
};
exports.SalaryBankingDetailsPage = SalaryBankingDetailsPage;
