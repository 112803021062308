"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apply_action_code = void 0;
var auth_1 = require("firebase/auth");
var firebase_1 = require("../../../../firebase");
var messages_1 = require("./constants/messages");
var update_user_by_verified_email_1 = require("../../sky_api/update_user_by_verified_email");
function apply_action_code(request) {
    return __awaiter(this, void 0, void 0, function () {
        var info, data, operation, email, previousEmail, _a, error_1, errorMessage;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, (0, auth_1.checkActionCode)(firebase_1.auth, request.code)];
                case 1:
                    info = _b.sent();
                    // Apply the action code to verify the email
                    return [4 /*yield*/, (0, auth_1.applyActionCode)(firebase_1.auth, request.code)];
                case 2:
                    // Apply the action code to verify the email
                    _b.sent();
                    data = info.data, operation = info.operation;
                    email = data.email, previousEmail = data.previousEmail;
                    if (!(email && previousEmail)) return [3 /*break*/, 5];
                    _a = operation;
                    switch (_a) {
                        case "VERIFY_AND_CHANGE_EMAIL": return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, (0, update_user_by_verified_email_1.updateUserByVerifiedEmail)({
                        email: email.toString(),
                        previousEmail: previousEmail.toString(),
                        operation: operation,
                    }).then(function (response) {
                        if (response.success) {
                            return {
                                success: true,
                                message: response.message,
                                content: { is_loading: false },
                            };
                        }
                        else {
                            return {
                                success: false,
                                message: response.message,
                                content: { is_loading: false },
                            };
                        }
                    })];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/, {
                        success: true,
                        message: "Email verified successfully.",
                        content: { is_loading: false },
                    }];
                case 6:
                    error_1 = _b.sent();
                    errorMessage = "";
                    switch (error_1 === null || error_1 === void 0 ? void 0 : error_1.code) {
                        case messages_1.EXPIRED_ACTION_CODE:
                            errorMessage = "This link expired, please request a new link.";
                            break;
                        case messages_1.INVALID_ACTION_CODE:
                            errorMessage = "This link is not valid, please request a new link.";
                            break;
                        default:
                            errorMessage = "An error occurred. Please try again.";
                    }
                    return [2 /*return*/, {
                            success: false,
                            message: errorMessage,
                            content: { is_loading: false },
                        }];
                case 7: return [2 /*return*/];
            }
        });
    });
}
exports.apply_action_code = apply_action_code;
