"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPage = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
// import { formatMoney } from "doshx_controls_web/lib/utilities";
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_product_1 = require("../../../api/calls/product/get_product");
var recently_viewed_1 = require("../../../api/calls/product/recently_viewed");
var add_product_thunk_1 = require("../../../reducers/slices/basket/thunks/add_product_thunk");
var main_1 = require("../../../reducers/slices/main");
var selection_1 = require("../../../reducers/slices/selection");
var page_path_1 = require("../components/page_path");
var symbols_1 = require("../../../constants/symbols");
var favourite_product_1 = require("../../../api/calls/product/favourite_product");
var get_favourite_products_1 = require("../../../api/calls/product/get_favourite_products");
var ProductPage = function () {
    var _a, _b, _c;
    // const details: IProductPage | undefined = sampleDetails;
    var params = (0, react_router_dom_1.useParams)();
    var productId = params.productId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }), basket = _d.basket, isAddingToBasket = _d.isAddingToBasket;
    var selection = (0, react_redux_1.useSelector)(function (state) { return state.selectionState; }).selection;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _e = (0, react_1.useState)(false), onWishlist = _e[0], setOnWishlist = _e[1];
    var _f = (0, react_1.useState)({
        _id: "",
        sku: "",
        title: "",
        sub_title: "",
        pid: "",
        description: "",
        price: "0",
        promotion_price: "0",
        on_promotion: false,
        category: {},
        image_url: "",
        image_list: [],
        rating: {
            average: "0",
            count: "0",
        },
    }), retrievedProduct = _f[0], setRetrievedProduct = _f[1];
    var _g = (0, react_1.useState)(false), isAddingToCart = _g[0], setIsAddingToCart = _g[1];
    var _h = (0, react_1.useState)([]), recentlyViewed = _h[0], setRecentlyViewed = _h[1];
    var _j = (0, react_1.useState)(1), quantity = _j[0], setQuantity = _j[1];
    var formatMoney = doshx_controls_web_1.Utilities.formatMoney;
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = selection.selectedProduct) === null || _a === void 0 ? void 0 : _a._id) !== null && _b !== void 0 ? _b : productId) {
            (0, get_product_1.getProduct)({
                _id: (_d = (_c = selection.selectedProduct) === null || _c === void 0 ? void 0 : _c._id) !== null && _d !== void 0 ? _d : productId,
                user_id: user === null || user === void 0 ? void 0 : user.uid,
            }).then(function (response) {
                setRetrievedProduct(response.content);
            });
        }
        else {
            navigate(-1);
        }
    }, [productId]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (user === null || user === void 0 ? void 0 : user.uid) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user === null || user === void 0 ? void 0 : user.uid) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        if (retrievedProduct && user)
            (0, get_favourite_products_1.get_favourite_products)().then(function (response) {
                var _a;
                if ((_a = response.content) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.pid === retrievedProduct.pid; })) {
                    setOnWishlist(true);
                }
                else {
                    setOnWishlist(false);
                }
            });
    }, [user, retrievedProduct]);
    var generatePriceDisplay = function () {
        if (retrievedProduct.on_promotion) {
            return (react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({ symbol: symbols_1.currencySymbol, value: retrievedProduct.promotion_price, decimalPlaces: 2 }), size: "xlarge", textColor: "text-secondary", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "line-through", label: formatMoney({ symbol: symbols_1.currencySymbol, value: retrievedProduct.price, decimalPlaces: 2 }) })));
        }
        else {
            return (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({ symbol: symbols_1.currencySymbol, value: retrievedProduct.price, decimalPlaces: 2 }), size: "xlarge", textColor: "text-secondary", bold: true }));
        }
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-4" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "/shop",
                        title: "Shop",
                    },
                    {
                        page: "/shop/category/0/".concat((_a = retrievedProduct === null || retrievedProduct === void 0 ? void 0 : retrievedProduct.category) === null || _a === void 0 ? void 0 : _a._id, "/").concat((_b = retrievedProduct === null || retrievedProduct === void 0 ? void 0 : retrievedProduct.category) === null || _b === void 0 ? void 0 : _b.name),
                        title: "".concat((_c = retrievedProduct === null || retrievedProduct === void 0 ? void 0 : retrievedProduct.category) === null || _c === void 0 ? void 0 : _c.name),
                    },
                    {
                        page: "/",
                        title: "".concat(retrievedProduct.title),
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "self-center" },
                    react_1.default.createElement(doshx_controls_web_1.GalleryControl, { images: retrievedProduct.image_list && retrievedProduct.image_list.length > 0
                            ? retrievedProduct.image_list.map(function (item, idx) {
                                return { id: idx.toString(), url: item.url };
                            })
                            : [{ id: "1", url: retrievedProduct.image_url }] })),
                react_1.default.createElement("div", { className: "flex flex-col flex-1 gap-8 lg:self-start" },
                    react_1.default.createElement("div", { className: "flex flex-col " },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "SKU: ".concat(retrievedProduct.sku) }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(retrievedProduct.title), size: "2xlarge" }),
                        react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: onWishlist ? "Remove from Wishlist" : "Add to Wishlist", icon: onWishlist ? pro_solid_svg_icons_1.faHeart : pro_light_svg_icons_1.faHeart, iconColor: "text-primary", textColor: "text-primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!(user === null || user === void 0 ? void 0 : user.uid)) return [3 /*break*/, 2];
                                                dispatch((0, main_1.setMessageModal)({
                                                    title: "Please Wait",
                                                    messages: ["Please wait while we process your request."],
                                                }));
                                                return [4 /*yield*/, (0, favourite_product_1.favorite_product)({
                                                        product_id: retrievedProduct === null || retrievedProduct === void 0 ? void 0 : retrievedProduct._id,
                                                    }).then(function (response) {
                                                        if (response.success) {
                                                            setOnWishlist(!onWishlist);
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Success",
                                                                messages: ["".concat(response === null || response === void 0 ? void 0 : response.message)],
                                                            }));
                                                        }
                                                    })];
                                            case 1:
                                                _a.sent();
                                                return [3 /*break*/, 3];
                                            case 2:
                                                dispatch((0, main_1.setDrawerPage)("login"));
                                                dispatch((0, main_1.setDrawerOpen)(true));
                                                _a.label = 3;
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); } }))),
                    generatePriceDisplay(),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: retrievedProduct.sub_title }),
                    react_1.default.createElement("div", { className: "flex flex-row" },
                        react_1.default.createElement(doshx_controls_web_1.IncrementControl, { borderColor: "border-primary", textColor: "text-primary", iconColor: "text-primary", value: quantity, onChange: function (v) {
                                setQuantity(v);
                            } }),
                        react_1.default.createElement("div", { className: "flex-1" }),
                        react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add To Cart", loading: isAddingToBasket, backgroundColor: "bg-primary", onClick: function () {
                                    dispatch((0, add_product_thunk_1.add_product_thunk)(__assign(__assign({}, retrievedProduct), { quantity: quantity })));
                                } }))))),
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "w-full" },
                    react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: {
                            tabs: {
                                indicatorColor: "bg-primary",
                                backgroundColor: "bg-gray-100",
                            },
                        }, tabs: [
                            {
                                title: "Description",
                                content: (react_1.default.createElement("div", { className: "p-4" },
                                    react_1.default.createElement("div", { className: "flex flex-col gap-4", dangerouslySetInnerHTML: { __html: retrievedProduct.description } }))),
                            },
                            // { title: "Specification", content: <div>Content 2</div> },
                            // { title: "Discussion", content: <div>Content 3</div> },
                            { title: "Reviews", content: react_1.default.createElement("div", { className: "p-4" }, "Coming soon...") },
                        ] }))),
            recentlyViewed && recentlyViewed.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductCarouselSmallControl, { category: {
                    name: "Recently viewed",
                }, products: recentlyViewed.map(function (product) {
                    return {
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                    };
                }), onClick: function (product) {
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                } })))));
};
exports.ProductPage = ProductPage;
