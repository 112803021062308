const colors = {
  //TODO: add more colors as needed
  primary: "#5D53A2",
  secondary: "#F26925",
  disabled: "#B0B0B0",
  success: "#00AA00",
  error: "#AA0000",
  borders: "#888888",
};

module.exports = colors;
