"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaceTecAppBlocked = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var user_1 = require("../../../reducers/slices/user");
var react_redux_1 = require("react-redux");
var FaceTecAppBlocked = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, user_1.sign_out_thunk)());
    }, []);
    return (react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
        react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "mt-10" },
                react_1.default.createElement("div", { className: "py-20 flex flex-col items-center gap-4" },
                    react_1.default.createElement("div", { className: "flex flex-col items-center gap-2" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Important Notice Regarding Your Account Status", size: "3xlarge", textColor: "text-primary", center: true })),
                    react_1.default.createElement("div", { className: "flex flex-col pt-4 pb-4 gap-1 justify-center items-center " },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "We hope this message finds you well. We would like to inform you that, after careful review, your account has been flagged and placed on our fraud watchlist due to suspicious activity. If you believe this is an error or would like to provide additional information, please reach out to our support team to resolve this matter.", size: "medium", textColor: "text-black", center: true })))))));
};
exports.FaceTecAppBlocked = FaceTecAppBlocked;
