"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanApplicationPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_router_dom_1 = require("react-router-dom");
var start_1 = require("./steps/start");
var financial_details_1 = require("./steps/financial_details");
var personal_details_1 = require("./steps/personal_details");
var next_of_kin_details_1 = require("./steps/next_of_kin_details");
var employment_1 = require("./steps/employment");
var pay_point_details_1 = require("./steps/pay_point_details");
var salary_banking_1 = require("./steps/salary_banking");
var documents_1 = require("./steps/documents");
var authorization_salary_deduction_1 = require("./steps/authorization_salary_deduction");
var authorization_electronic_payments_1 = require("./steps/authorization_electronic_payments");
var complete_1 = require("./steps/complete");
var agreement_of_sale_1 = require("./steps/agreement_of_sale");
var kyc_1 = require("./steps/kyc");
var steps_1 = require("./steps/constants/steps");
var LoanApplicationPage = function () {
    var params = (0, react_router_dom_1.useParams)();
    var step = params.step;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "w-full" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null, (function () {
                    switch (step) {
                        case steps_1.APP_STEP_START:
                            return react_1.default.createElement(start_1.LoanApplicationStart, null);
                        case steps_1.APP_STEP_KYC:
                            return react_1.default.createElement(kyc_1.SumSubPage, null);
                        case steps_1.APP_STEP_PERSONAL_DETAILS:
                            return react_1.default.createElement(personal_details_1.PersonalDetailsPage, null);
                        case steps_1.APP_STEP_FAMILY_DETAILS:
                            return react_1.default.createElement(next_of_kin_details_1.NextOfKinDetailsPage, null);
                        case steps_1.APP_STEP_EMPLOYMENT_DETAILS:
                            return react_1.default.createElement(employment_1.EmploymentDataPage, null);
                        case steps_1.APP_STEP_PAYPOINT_DETAILS:
                            return react_1.default.createElement(pay_point_details_1.PayPointDetailsPage, null);
                        case steps_1.APP_STEP_BANKING_DETAILS:
                            return react_1.default.createElement(salary_banking_1.SalaryBankingDetailsPage, null);
                        case steps_1.APP_STEP_FINANCIAL_DETAILS:
                            return react_1.default.createElement(financial_details_1.FinancialDetails, null);
                        case steps_1.APP_STEP_DOCUMENTS:
                            return react_1.default.createElement(documents_1.DocumentsPage, null);
                        case steps_1.APP_STEP_AGREEMENT_OF_SALE:
                            return react_1.default.createElement(agreement_of_sale_1.AgreementOfSale, null);
                        case steps_1.APP_STEP_AUTHORIZATION_SALARY_DEDUCTION:
                            return react_1.default.createElement(authorization_salary_deduction_1.AuthorizationSalaryDeduction, null);
                        case steps_1.APP_STEP_AUTHORIZATION_ELECTRONIC_PAYMENTS:
                            return react_1.default.createElement(authorization_electronic_payments_1.AuthorizationElectronicPayments, null);
                        case steps_1.APP_STEP_COMPLETE:
                            return react_1.default.createElement(complete_1.LoanApplicationComplete, null);
                    }
                })())))));
};
exports.LoanApplicationPage = LoanApplicationPage;
