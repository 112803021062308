"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundFileUtilities = void 0;
// Load custom sound files
var FACESCAN_SUCCESSFUL_SOUND_FILE = require("../../sample-app-resources/Vocal_Guidance_Audio_Files/facescan_successful_sound_file.mp3").default;
var PLEASE_FRAME_YOUR_FACE_SOUND_FILE = require("../../sample-app-resources/Vocal_Guidance_Audio_Files/please_frame_your_face_sound_file.mp3").default;
var PLEASE_MOVE_CLOSER_SOUND_FILE = require("../../sample-app-resources/Vocal_Guidance_Audio_Files/please_move_closer_sound_file.mp3").default;
var PLEASE_PRESS_BUTTON_SOUND_FILE = require("../../sample-app-resources/Vocal_Guidance_Audio_Files/please_press_button_sound_file.mp3").default;
var PLEASE_RETRY_SOUND_FILE = require("../../sample-app-resources/Vocal_Guidance_Audio_Files/please_retry_sound_file.mp3").default;
var UPLOADING_SOUND_FILE = require("../../sample-app-resources/Vocal_Guidance_Audio_Files/uploading_sound_file.mp3").default;
var SoundFileUtilities = /** @class */ (function () {
    function SoundFileUtilities() {
        // Return the customization object updated with custom sound files
        this.setVocalGuidanceSoundFiles = function (currentCustomization) {
            currentCustomization.vocalGuidanceCustomization.pleaseFrameYourFaceInTheOvalSoundFile = PLEASE_FRAME_YOUR_FACE_SOUND_FILE;
            currentCustomization.vocalGuidanceCustomization.pleaseMoveCloserSoundFile = PLEASE_MOVE_CLOSER_SOUND_FILE;
            currentCustomization.vocalGuidanceCustomization.pleaseRetrySoundFile = PLEASE_RETRY_SOUND_FILE;
            currentCustomization.vocalGuidanceCustomization.uploadingSoundFile = UPLOADING_SOUND_FILE;
            currentCustomization.vocalGuidanceCustomization.facescanSuccessfulSoundFile = FACESCAN_SUCCESSFUL_SOUND_FILE;
            currentCustomization.vocalGuidanceCustomization.pleasePressTheButtonToStartSoundFile = PLEASE_PRESS_BUTTON_SOUND_FILE;
            return currentCustomization;
        };
    }
    return SoundFileUtilities;
}());
exports.SoundFileUtilities = SoundFileUtilities;
