"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var cancel_basket_1 = require("../../../api/calls/basket/cancel_basket");
var basket_1 = require("../../../reducers/slices/basket/basket");
var get_basket_thunk_1 = require("../../../reducers/slices/basket/thunks/get_basket_thunk");
var main_1 = require("../../../reducers/slices/main");
var empty_1 = require("./empty");
var populated_1 = require("./populated");
var BasketPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    (0, react_1.useEffect)(function () {
        dispatch((0, get_basket_thunk_1.get_basket_thunk)({ basket_id: "" }));
    }, []);
    (0, react_1.useEffect)(function () {
        if (basket && basket.product_list.length == 0) {
            (0, cancel_basket_1.cancel_basket)({
                _id: basket._id,
            }).then(function (response) {
                if (response.success) {
                    dispatch((0, basket_1.setBasket)(null));
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Something went wrong!",
                        messages: [response.message],
                    }));
                }
            });
        }
    }, [basket]);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-4" }, basket && basket.product_list.length > 0 ? react_1.default.createElement(populated_1.PopulatedBasket, null) : react_1.default.createElement(empty_1.EmptyBasket, null))));
};
exports.BasketPage = BasketPage;
