"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_loan_application = exports.create_loan_application = void 0;
var firestore_1 = require("firebase/firestore");
var firebase_1 = require("../../../firebase");
var store_1 = require("../../../store");
var functions_1 = require("../../../constants/functions");
function create_loan_application(request) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return __awaiter(this, void 0, void 0, function () {
        var state, userState, userDocRef, providerRef, _m, branchRef, _o, loansCollectionRef, newLoanDocRef, newLoanDocSnapshot, loanData, error_1;
        return __generator(this, function (_p) {
            switch (_p.label) {
                case 0:
                    _p.trys.push([0, 11, , 12]);
                    state = store_1.redux_store.getState();
                    userState = state.userState;
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "users", "".concat(userState.user.uid))];
                case 1:
                    userDocRef = _p.sent();
                    if (!request.provider) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "providers", (_a = request.provider) !== null && _a !== void 0 ? _a : "")];
                case 2:
                    _m = _p.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _m = "";
                    _p.label = 4;
                case 4:
                    providerRef = _m;
                    if (!request.branch) return [3 /*break*/, 6];
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "branches", (_b = request.branch) !== null && _b !== void 0 ? _b : "")];
                case 5:
                    _o = _p.sent();
                    return [3 /*break*/, 7];
                case 6:
                    _o = "";
                    _p.label = 7;
                case 7:
                    branchRef = _o;
                    return [4 /*yield*/, (0, firestore_1.collection)(firebase_1.firestore, "loan_applications")];
                case 8:
                    loansCollectionRef = _p.sent();
                    return [4 /*yield*/, (0, firestore_1.addDoc)(loansCollectionRef, {
                            amount: parseFloat((_c = request === null || request === void 0 ? void 0 : request.amount) !== null && _c !== void 0 ? _c : "0"),
                            provider: providerRef !== null && providerRef !== void 0 ? providerRef : "",
                            branch: branchRef !== null && branchRef !== void 0 ? branchRef : "",
                            basket_id: (_d = request === null || request === void 0 ? void 0 : request.basket_id) !== null && _d !== void 0 ? _d : "",
                            date_started: new Date(),
                            code: Math.random().toString(36).substring(2, 10).toUpperCase(),
                            user: userDocRef,
                            status: (_e = request.status) !== null && _e !== void 0 ? _e : "DRAFT",
                            docs_complete: false,
                            employment_complete: false,
                            family_complete: false,
                            financial_complete: false,
                            friend_complete: false,
                            is_product: (_f = request.is_product) !== null && _f !== void 0 ? _f : false,
                            paypoint_complete: false,
                            personal_complete: false,
                            sumsub_complete: false,
                            agree_terms: (_g = request.agree_terms) !== null && _g !== void 0 ? _g : false,
                            authoriseBank_complete: false,
                            authoriseSalary_complete: false,
                            bankDetails_complete: false,
                            term: (_h = request.term) !== null && _h !== void 0 ? _h : "12 Months",
                            order_id: (_j = request === null || request === void 0 ? void 0 : request.order_id) !== null && _j !== void 0 ? _j : "",
                            order_number: (_k = request === null || request === void 0 ? void 0 : request.order_number) !== null && _k !== void 0 ? _k : "",
                            products: (_l = request === null || request === void 0 ? void 0 : request.products) !== null && _l !== void 0 ? _l : null,
                        })];
                case 9:
                    newLoanDocRef = _p.sent();
                    return [4 /*yield*/, (0, firestore_1.getDoc)(newLoanDocRef)];
                case 10:
                    newLoanDocSnapshot = _p.sent();
                    if (newLoanDocSnapshot.exists()) {
                        loanData = newLoanDocSnapshot.data();
                        return [2 /*return*/, { success: true, message: "Loan Application Started", content: __assign({ uid: newLoanDocSnapshot === null || newLoanDocSnapshot === void 0 ? void 0 : newLoanDocSnapshot.id }, loanData) }];
                    }
                    else {
                        return [2 /*return*/, { success: false, message: "Failed to create loan application", content: null }];
                    }
                    return [3 /*break*/, 12];
                case 11:
                    error_1 = _p.sent();
                    return [2 /*return*/, { success: false, message: "An error occurred", content: null }];
                case 12: return [2 /*return*/];
            }
        });
    });
}
exports.create_loan_application = create_loan_application;
function update_loan_application(request) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var loanDocRef, updateData, _d, _e, _f, _g, _h, _j, _k, _l, _m, loanDocSnapshot, loanData, error_2;
        return __generator(this, function (_o) {
            switch (_o.label) {
                case 0:
                    _o.trys.push([0, 15, , 16]);
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "loan_applications", "".concat(request.uid))];
                case 1:
                    loanDocRef = _o.sent();
                    //remove null objects from reques
                    request = (0, functions_1.removeUndefinedProperties)(request);
                    updateData = {};
                    if (request.docs_complete)
                        updateData["docs_complete"] = request.docs_complete;
                    if (request.employment_complete)
                        updateData["employment_complete"] = request.employment_complete;
                    if (request.family_complete)
                        updateData["family_complete"] = request.family_complete;
                    if (request.financial_complete)
                        updateData["financial_complete"] = request.financial_complete;
                    if (request.friend_complete)
                        updateData["friend_complete"] = request.friend_complete;
                    if (request.bankDetails_complete)
                        updateData["bankDetails_complete"] = request.bankDetails_complete;
                    if (request.paypoint_complete)
                        updateData["paypoint_complete"] = request.paypoint_complete;
                    if (request.sumsub_complete)
                        updateData["sumsub_complete"] = request.sumsub_complete;
                    if (request.personal_complete)
                        updateData["personal_complete"] = request.personal_complete;
                    if (request.agree_terms)
                        updateData["agree_terms"] = request.agree_terms;
                    if (request.authoriseBank_complete)
                        updateData["authoriseBank_complete"] = request.authoriseBank_complete;
                    if (request.authoriseSalary_complete)
                        updateData["authoriseSalary_complete"] = request.authoriseSalary_complete;
                    if (request.spouse)
                        updateData["spouse"] = request.spouse;
                    if (request.home_address)
                        updateData["home_address"] = request.home_address;
                    if (request.postal_address)
                        updateData["postal_address"] = request.postal_address;
                    if (request.family_member)
                        updateData["family_member"] = request.family_member;
                    if (request.friend)
                        updateData["friend"] = request.friend;
                    if (request.employment_details)
                        updateData["employment_details"] = request.employment_details;
                    if (request.salary_bank_details)
                        updateData["salary_bank_details"] = request.salary_bank_details;
                    if (request.financial_detail)
                        updateData["financial_detail"] = request.financial_detail;
                    if (request.latest_payslip)
                        updateData["latest_payslip"] = request.latest_payslip;
                    if (request.bank_statements)
                        updateData["bank_statements"] = request.bank_statements;
                    if (request.copy_of_id)
                        updateData["copy_of_id"] = request.copy_of_id;
                    if (request.force_number)
                        updateData["force_number"] = request.force_number;
                    if (request.authoriseSalary_complete)
                        updateData["authoriseSalary_complete"] = request.authoriseSalary_complete;
                    if (request.bank_debit_signature)
                        updateData["bank_debit_signature"] = request.bank_debit_signature;
                    if (request.salary_deduction_signature)
                        updateData["salary_deduction_signature"] = request.salary_deduction_signature;
                    if (request.status)
                        updateData["status"] = request.status;
                    if (request.term)
                        updateData["term"] = request.term;
                    if (request.paypoint_details)
                        updateData["paypoint_details"] = request.paypoint_details;
                    if (!request.provider) return [3 /*break*/, 5];
                    _d = updateData;
                    _e = "provider";
                    if (!request.provider) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "providers", (_a = request.provider) !== null && _a !== void 0 ? _a : "")];
                case 2:
                    _f = _o.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _f = "";
                    _o.label = 4;
                case 4:
                    _d[_e] = _f;
                    _o.label = 5;
                case 5:
                    if (!request.branch) return [3 /*break*/, 9];
                    _g = updateData;
                    _h = "branch";
                    if (!request.branch) return [3 /*break*/, 7];
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "branches", (_b = request.branch) !== null && _b !== void 0 ? _b : "")];
                case 6:
                    _j = _o.sent();
                    return [3 /*break*/, 8];
                case 7:
                    _j = "";
                    _o.label = 8;
                case 8:
                    _g[_h] = _j;
                    _o.label = 9;
                case 9:
                    if (request.order_id)
                        updateData["order_id"] = request.order_id;
                    if (request.order_number)
                        updateData["order_number"] = request.order_number;
                    if (!request.salesAgreement) return [3 /*break*/, 13];
                    _k = updateData;
                    _l = "salesAgreement";
                    if (!request.salesAgreement) return [3 /*break*/, 11];
                    return [4 /*yield*/, (0, firestore_1.doc)(firebase_1.firestore, "deviceSalesAgreements", (_c = request.salesAgreement) !== null && _c !== void 0 ? _c : "")];
                case 10:
                    _m = _o.sent();
                    return [3 /*break*/, 12];
                case 11:
                    _m = "";
                    _o.label = 12;
                case 12:
                    _k[_l] = _m;
                    _o.label = 13;
                case 13:
                    // Update Firebase Doc
                    (0, firestore_1.updateDoc)(loanDocRef, updateData);
                    return [4 /*yield*/, (0, firestore_1.getDoc)(loanDocRef)];
                case 14:
                    loanDocSnapshot = _o.sent();
                    if (loanDocSnapshot.exists()) {
                        loanData = loanDocSnapshot.data();
                        return [2 /*return*/, { success: true, message: "Loan Application Updated", content: __assign({ uid: loanDocSnapshot === null || loanDocSnapshot === void 0 ? void 0 : loanDocSnapshot.id }, loanData) }];
                    }
                    else {
                        return [2 /*return*/, { success: false, message: "Failed to update loan application", content: null }];
                    }
                    return [3 /*break*/, 16];
                case 15:
                    error_2 = _o.sent();
                    return [2 /*return*/, { success: false, message: "An error occurred", content: null }];
                case 16: return [2 /*return*/];
            }
        });
    });
}
exports.update_loan_application = update_loan_application;
