"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalInfoWidget = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_router_dom_1 = require("react-router-dom");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var AdditionalInfoWidget = function (props) {
    var _a = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)(null), timer = _b[0], setTimer = _b[1];
    //
    (0, react_1.useEffect)(function () {
        return function () {
            if (timer) {
                // console.log("clearing timer");
                clearTimeout(timer);
            }
        };
    }, []);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-col gap-4 mt-8" },
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement("div", { className: "flex flex-row justify-start items-center gap-2" },
                react_1.default.createElement("span", { className: "  " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faInfoCircle })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Additional Information", size: "large", textColor: "text-black" })),
            react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal" }),
            react_1.default.createElement("div", { className: "flex flex-col justify-start  gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Optional", bold: true, textColor: "text-black" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Note: This information will be crucial should you choose to  apply for credit with us.", textColor: "text-black", size: "small" }),
                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Advanced Personal Details", size: "medium", textColor: "text-secondary", onClick: function () {
                        dispatch((0, main_1.setDrawerOpen)(false));
                        navigate("/advanced-personal-details");
                    } }),
                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Employment Details", size: "medium", textColor: "text-secondary", onClick: function () {
                        dispatch((0, main_1.setDrawerOpen)(false));
                        navigate("/employment-details");
                    } })))));
};
exports.AdditionalInfoWidget = AdditionalInfoWidget;
