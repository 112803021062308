"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoansPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../components/page_path");
var react_router_dom_1 = require("react-router-dom");
var get_loans_1 = require("../../../api/calls/loan/get_loans");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var symbols_1 = require("../../../constants/symbols");
var react_redux_1 = require("react-redux");
var sign_in_required_1 = require("../components/sign_in_required");
var steps_1 = require("./steps/constants/steps");
var route_names_1 = require("../../../constants/route_names");
var LoansPage = function () {
    var params = (0, react_router_dom_1.useParams)();
    var step = params.step;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)([]), userLoans = _a[0], setUserLoans = _a[1];
    var _b = (0, react_1.useState)(false), isLoading = _b[0], setIsLoading = _b[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        //Get Provider
        setIsLoading(true);
        if (user === null || user === void 0 ? void 0 : user.uid) {
            (0, get_loans_1.getUserLoans)().then(function (response) {
                var _a;
                if (response.success) {
                    var tempData_1 = [];
                    (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.forEach(function (doc) {
                        var loanData = doc.data();
                        tempData_1.push(__assign({ uid: doc.id }, loanData));
                    });
                    tempData_1.sort(function (a, b) { var _a, _b; return ((_a = b.date_started) === null || _a === void 0 ? void 0 : _a.seconds) - ((_b = a.date_started) === null || _b === void 0 ? void 0 : _b.seconds); });
                    setUserLoans(tempData_1);
                    setIsLoading(false);
                }
                else {
                }
            });
        }
    }, [user]);
    var renderLoans = function (statuses) {
        return (react_1.default.createElement("div", { className: "w-full" }, userLoans && userLoans.length > 0 ? (userLoans.map(function (loan, index) {
            // console.log("order", order);
            var _a;
            if (statuses.includes(loan.status)) {
                return (react_1.default.createElement("div", { onClick: function () {
                        navigate("/loan/details/".concat(loan.uid));
                    }, key: index },
                    react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "shadow hover:shadow-lg transition-shadow cursor-pointer", borderColor: "border-transparent" },
                        react_1.default.createElement("div", { key: index, className: "flex flex-row items-center gap-4" },
                            react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "cursor-pointer", label: loan.status == "APPROVED" ? "Loan Number: ".concat((_a = loan === null || loan === void 0 ? void 0 : loan.loan_number) !== null && _a !== void 0 ? _a : "") : "Application Number: ".concat(loan.code), bold: true, size: "medium", textColor: "text-black" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({ symbol: symbols_1.currencySymbol, value: loan.amount.toString(), decimalPlaces: 2 }), size: "large" })),
                            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-2 justify-center items-center" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "cursor-pointer", label: doshx_controls_web_1.Utilities.formatDateTime(loan.date_started.seconds * 1000, "MMMM DD, YYYY"), size: "xsmall", textColor: "text-black" }),
                                (function () {
                                    switch (loan.status) {
                                        case "APPROVED":
                                            return (react_1.default.createElement("div", { className: "flex flex-row gap-2 text-green-600 justify-center items-center" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle }),
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "APPROVED", textColor: "text-green-600" })));
                                            break;
                                        case "AWAIT":
                                            return (react_1.default.createElement("div", { className: "flex flex-row gap-2 text-orange-600 justify-center items-center" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faQuestionCircle }),
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "AWAITING APPROVAL", textColor: "text-orange-600" })));
                                            break;
                                        case "DRAFT":
                                            return (react_1.default.createElement("div", { className: "flex flex-row gap-2 text-gray-600 justify-center items-center" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPenCircle }),
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "DRAFT", textColor: "text-gray-600" })));
                                            break;
                                        case "DECLINED":
                                            return (react_1.default.createElement("div", { className: "flex flex-row gap-2 text-red-600 justify-center items-center" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimesCircle }),
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "DECLINED", textColor: "text-red-600" })));
                                            break;
                                        case "ARCHIVED":
                                            return (react_1.default.createElement("div", { className: "flex flex-row gap-2 text-yellow-600 justify-center items-center" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faArchive }),
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "ARCHIVED", textColor: "text-yellow-600" })));
                                            break;
                                        default:
                                            return (react_1.default.createElement("div", { className: "flex flex-row gap-2 text-blue-600 justify-center items-center" },
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faExclamationCircle }),
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "UNKNOWN", textColor: "text-blue-600" })));
                                            break;
                                    }
                                })())))));
            }
        })) : isLoading ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You dont have any loans in this category! Apply for a loan.", size: "medium", textColor: "text-black" }))));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "w-full" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
                    react_1.default.createElement(page_path_1.PagePath, { path: [
                            {
                                page: "/",
                                title: "Home",
                            },
                            {
                                page: "",
                                title: "Loans",
                                active: true,
                            },
                        ] }),
                    (user === null || user === void 0 ? void 0 : user.uid) ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement("div", { className: "flex justify-end" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "New Application", backgroundColor: "bg-primary", size: "small", onClick: function () {
                                    navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_START, "/0"));
                                } })),
                        react_1.default.createElement(doshx_controls_web_1.AccordionControl, { addHeaderPadding: false, addContentPadding: true, arrowSide: "left", collapsePreviousItems: true, items: [
                                {
                                    header: react_1.default.createElement("div", { className: "" }, "PENDING"),
                                    content: react_1.default.createElement("div", { className: "flex flex-row w-full gap-2" }, renderLoans(["DRAFT", "AWAIT"])),
                                    expanded: userLoans.some(function (loan) { return loan.status === "DRAFT"; }) || userLoans.some(function (loan) { return loan.status === "AWAIT"; }),
                                },
                                {
                                    header: react_1.default.createElement("div", { className: "" }, "APPROVED"),
                                    content: react_1.default.createElement("div", { className: "flex flex-row w-full gap-2" }, renderLoans(["APPROVED"])),
                                    expanded: userLoans.some(function (loan) { return loan.status === "APPROVED"; }),
                                },
                                {
                                    header: react_1.default.createElement("div", { className: "" }, "DECLINED"),
                                    content: react_1.default.createElement("div", { className: "flex flex-row w-full gap-2" }, renderLoans(["DECLINED"])),
                                    expanded: false,
                                },
                                {
                                    header: react_1.default.createElement("div", { className: "" }, "ARCHIVED"),
                                    content: react_1.default.createElement("div", { className: "flex flex-row w-full gap-2" }, renderLoans(["ARCHIVED"])),
                                    expanded: false,
                                },
                            ], 
                            // onExpansionChanged={(items) => console.log("expanded Items", items)}
                            colors: {
                                // titleTextColor: "text-red-500",
                                // titleBackgroundColor: "bg-green-500",
                                // contentTextColor: "text-orange-500",
                                // contentBackgroundColor: "bg-blue-500",
                                borderColor: "border-transparent",
                            } }))) : (react_1.default.createElement(sign_in_required_1.SignInRequiredComponent, null)))))));
};
exports.LoansPage = LoansPage;
