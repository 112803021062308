"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServicesMainComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var categories_1 = require("../../../../api/calls/product/categories");
var ServicesMainComponent = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)([]), shopCategories = _a[0], setShopCategories = _a[1];
    (0, react_1.useEffect)(function () {
        //
    }, []);
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)({
            page: "1",
        }).then(function (response) {
            var categoryList = response.content.product_category_list;
            var newCategoryList = [];
            for (var _i = 0, _a = categoryList !== null && categoryList !== void 0 ? categoryList : []; _i < _a.length; _i++) {
                var category = _a[_i];
                if (category.description != "advert") {
                    newCategoryList.push({
                        image: category.image_url,
                        label: category.name,
                        url: "/shop/category/0/".concat(category._id, "/").concat(encodeURI(category.name)),
                    });
                }
            }
            setShopCategories(newCategoryList);
        });
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8   rounded-xl p-10 justify-evenly" },
        react_1.default.createElement("div", { className: "flex justify-evenly gap-4" }, shopCategories.map(function (category, index) {
            return (react_1.default.createElement("div", { key: index, className: "flex flex-col justify-center items-center bg-white rounded-xl gap-4 h-60 w-60 cursor-pointer shadow-lg p-2", onClick: function () {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    navigate(category.url);
                } },
                react_1.default.createElement("img", { className: "h-280", src: category.image }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: category === null || category === void 0 ? void 0 : category.label, textColor: "text-black", size: "medium", bold: true })));
        }))));
};
exports.ServicesMainComponent = ServicesMainComponent;
