"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TermsAndConditionsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var terms_1 = require("../../api/calls/client/terms");
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var page_path_1 = require("./components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var TermsAndConditionsPage = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), termsData = _a[0], setTermsData = _a[1];
    (0, react_1.useEffect)(function () {
        (0, terms_1.client_terms)().then(function (response) {
            if (response.success) {
                setTermsData(response.content.terms);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    }, []);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-4" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "",
                        title: "Terms and Conditions",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-row justify-between items-center " },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { bold: true, size: "4xlarge", label: "Terms and Conditions", textColor: "text-primary" }),
                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Full Screen", icon: pro_solid_svg_icons_1.faExternalLink, textColor: "text-secondary", iconColor: "text-secondary", onClick: function () {
                        var link = document.createElement("a");
                        link.href = "/docs/SKY_APP_TERMS_AND_CONDITIONS.pdf";
                        link.target = "_blank";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } })),
            react_1.default.createElement("iframe", { title: "PDF Viewer", width: "100%", height: "1000px", src: "/docs/SKY_APP_TERMS_AND_CONDITIONS.pdf" }))));
};
exports.TermsAndConditionsPage = TermsAndConditionsPage;
