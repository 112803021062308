"use strict";
//
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sign_up = void 0;
var auth_1 = require("firebase/auth");
var api_1 = require("../../../api");
var firestore_1 = require("firebase/firestore");
var firebase_1 = require("../../../../firebase");
function sign_up(request) {
    var _a, _b, _c, _d, _e;
    return __awaiter(this, void 0, void 0, function () {
        var userCredential, userDocRef, cell_number, letsTradeUser, password, requestWithoutPassword, userDocSnapshot, userProfile, error_1;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, (0, auth_1.createUserWithEmailAndPassword)(firebase_1.auth, request.email.trim(), request.password)];
                case 1:
                    userCredential = _f.sent();
                    userDocRef = (0, firestore_1.doc)(firebase_1.firestore, "users", userCredential.user.uid);
                    cell_number = (_a = request === null || request === void 0 ? void 0 : request.cell_number) === null || _a === void 0 ? void 0 : _a.phone_number;
                    return [4 /*yield*/, new api_1.API().apiPost({
                            baseUrl: (_b = process.env.LETS_TRADE_USER_URL) !== null && _b !== void 0 ? _b : "",
                            endpoint: "user/sign_up",
                            content: __assign(__assign({}, request), { cell_number: cell_number }),
                        })];
                case 2:
                    letsTradeUser = _f.sent();
                    if (!letsTradeUser.success) {
                        return [2 /*return*/, { success: false, message: letsTradeUser.message, content: { is_loading: false } }];
                    }
                    password = request.password, requestWithoutPassword = __rest(request, ["password"]);
                    return [4 /*yield*/, (0, firestore_1.setDoc)(userDocRef, __assign(__assign({}, requestWithoutPassword), { uid: userCredential.user.uid, auth_token: (_c = letsTradeUser === null || letsTradeUser === void 0 ? void 0 : letsTradeUser.content) === null || _c === void 0 ? void 0 : _c.token, integration_id: (_e = (_d = letsTradeUser === null || letsTradeUser === void 0 ? void 0 : letsTradeUser.content) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e._id, numberVerified: true, created_time: new Date() }))];
                case 3:
                    _f.sent();
                    return [4 /*yield*/, (0, firestore_1.getDoc)(userDocRef)];
                case 4:
                    userDocSnapshot = _f.sent();
                    if (userDocSnapshot.exists()) {
                        userProfile = userDocSnapshot.data();
                        return [2 /*return*/, { success: true, message: "Account Created &  Authenticated", content: __assign({ is_loading: false }, userProfile) }];
                    }
                    else {
                        return [2 /*return*/, { success: false, message: "User profile not found", content: { is_loading: false } }];
                    }
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _f.sent();
                    console.log(error_1);
                    return [2 /*return*/, { success: false, message: "", content: { is_loading: false } }];
                case 6: return [2 /*return*/];
            }
        });
    });
}
exports.sign_up = sign_up;
