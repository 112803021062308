"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var update_advanced_user_details_1 = require("../../../../api/calls/user/update_advanced_user_details");
var main_1 = require("../../../../reducers/slices/main");
var get_user_details_1 = require("../../../../api/calls/user/get_user_details");
var application_1 = require("../../../../api/calls/loan/application");
var functions_1 = require("../../../../constants/functions");
var address_list_1 = require("../../../../api/calls/user/address_list");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var steps_1 = require("./constants/steps");
var route_names_1 = require("../../../../constants/route_names");
var PersonalDetailsPage = function () {
    var _a, _b, _c, _d, _e;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var _f = (0, react_1.useState)(""), name = _f[0], setName = _f[1];
    var _g = (0, react_1.useState)(""), surname = _g[0], setSurname = _g[1];
    var _h = (0, react_1.useState)(""), idNumber = _h[0], setIdNumber = _h[1];
    var _j = (0, react_1.useState)(""), postalAddress = _j[0], setPostalAddress = _j[1];
    var _k = (0, react_1.useState)(""), homeAddress = _k[0], setHomeAddress = _k[1];
    var _l = (0, react_1.useState)(""), gender = _l[0], setGender = _l[1];
    var _m = (0, react_1.useState)(false), married = _m[0], setMarried = _m[1];
    var _o = (0, react_1.useState)(""), marriageType = _o[0], setMarriageType = _o[1];
    //Spouse Details
    var _p = (0, react_1.useState)(""), spouseNameMessage = _p[0], setSpouseNameMessage = _p[1];
    var _q = (0, react_1.useState)(""), spouseSurnameMessage = _q[0], setSpouseSurnameMessage = _q[1];
    var _r = (0, react_1.useState)(""), spouseCellNumberMessage = _r[0], setSpouseCellNumberMessage = _r[1];
    var _s = (0, react_1.useState)(""), spouseHomeNumberMessage = _s[0], setSpouseHomeNumberMessage = _s[1];
    var _t = (0, react_1.useState)(""), spouseWorkNumberMessage = _t[0], setSpouseWorkNumberMessage = _t[1];
    var _u = (0, react_1.useState)(""), spouseName = _u[0], setSpouseName = _u[1];
    var _v = (0, react_1.useState)(""), spouseSurname = _v[0], setSpouseSurname = _v[1];
    var _w = (0, react_1.useState)({ phone_number: "", country_code: "+264", country_abbreviation: "NA" }), spouseCellNumber = _w[0], setSpouseCellNumber = _w[1];
    var _x = (0, react_1.useState)({ phone_number: "", country_code: "+264", country_abbreviation: "NA" }), spouseHomeNumber = _x[0], setSpouseHomeNumber = _x[1];
    var _y = (0, react_1.useState)({ phone_number: "", country_code: "+264", country_abbreviation: "NA" }), spouseWorkNumber = _y[0], setSpouseWorkNumber = _y[1];
    //Error Messages
    var _z = (0, react_1.useState)(""), nameMessage = _z[0], setNameMessage = _z[1];
    var _0 = (0, react_1.useState)(""), surnameMessage = _0[0], setSurnameMessage = _0[1];
    var _1 = (0, react_1.useState)(""), idMessage = _1[0], setIdMessage = _1[1];
    var _2 = (0, react_1.useState)(""), postalAddressMessage = _2[0], setPostalAddressMessage = _2[1];
    var _3 = (0, react_1.useState)(""), homeAddressMessage = _3[0], setHomeAddressMessage = _3[1];
    var _4 = (0, react_1.useState)(""), genderMessage = _4[0], setGenderMessage = _4[1];
    var _5 = (0, react_1.useState)(""), marriageTypeMessage = _5[0], setMarriageTypeMessage = _5[1];
    //
    var _6 = (0, react_1.useState)(false), isSaving = _6[0], setIsSaving = _6[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _7 = (0, react_1.useState)([]), addressList = _7[0], setAddressList = _7[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (user.uid) {
            (0, address_list_1.address_list)({
                user_id: (_a = user === null || user === void 0 ? void 0 : user.integration_id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                if (response.success) {
                    setAddressList(response.content.addresses);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
            (0, get_user_details_1.get_user_details)().then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                var _a, _b, _c;
                var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
                return __generator(this, function (_y) {
                    switch (_y.label) {
                        case 0:
                            if (!response.success) return [3 /*break*/, 4];
                            setGender((_d = response === null || response === void 0 ? void 0 : response.content) === null || _d === void 0 ? void 0 : _d.gender);
                            setMarried((_e = response === null || response === void 0 ? void 0 : response.content) === null || _e === void 0 ? void 0 : _e.is_married);
                            setHomeAddress((_g = (_f = response === null || response === void 0 ? void 0 : response.content) === null || _f === void 0 ? void 0 : _f.home_address) === null || _g === void 0 ? void 0 : _g.full_address);
                            setPostalAddress((_h = response === null || response === void 0 ? void 0 : response.content) === null || _h === void 0 ? void 0 : _h.postal_address);
                            setName((_j = response === null || response === void 0 ? void 0 : response.content) === null || _j === void 0 ? void 0 : _j.name);
                            setSurname((_k = response === null || response === void 0 ? void 0 : response.content) === null || _k === void 0 ? void 0 : _k.surname);
                            setIdNumber((_l = response === null || response === void 0 ? void 0 : response.content) === null || _l === void 0 ? void 0 : _l.id_number);
                            setSpouseName((_o = (_m = response === null || response === void 0 ? void 0 : response.content) === null || _m === void 0 ? void 0 : _m.spouse) === null || _o === void 0 ? void 0 : _o.name);
                            setSpouseSurname((_q = (_p = response === null || response === void 0 ? void 0 : response.content) === null || _p === void 0 ? void 0 : _p.spouse) === null || _q === void 0 ? void 0 : _q.surname);
                            _a = setSpouseCellNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_s = (_r = response === null || response === void 0 ? void 0 : response.content) === null || _r === void 0 ? void 0 : _r.spouse) === null || _s === void 0 ? void 0 : _s.phone_number)];
                        case 1:
                            _a.apply(void 0, [_y.sent()]);
                            _b = setSpouseHomeNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_u = (_t = response === null || response === void 0 ? void 0 : response.content) === null || _t === void 0 ? void 0 : _t.spouse) === null || _u === void 0 ? void 0 : _u.home_number)];
                        case 2:
                            _b.apply(void 0, [_y.sent()]);
                            _c = setSpouseWorkNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_w = (_v = response === null || response === void 0 ? void 0 : response.content) === null || _v === void 0 ? void 0 : _v.spouse) === null || _w === void 0 ? void 0 : _w.work_number)];
                        case 3:
                            _c.apply(void 0, [_y.sent()]);
                            setMarriageType((_x = response === null || response === void 0 ? void 0 : response.content) === null || _x === void 0 ? void 0 : _x.marriage_type);
                            return [3 /*break*/, 5];
                        case 4:
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            _y.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
        }
        //
    }, [user]);
    var savePersonalDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var personalUpdated;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateDetails()) return [3 /*break*/, 3];
                    setIsSaving(true);
                    return [4 /*yield*/, (0, update_advanced_user_details_1.update_advanced_user_details)({
                            gender: gender,
                            id_number: idNumber,
                            is_married: married,
                            marriage_type: marriageType,
                            postal_address: postalAddress,
                            home_address: {
                                full_address: homeAddress,
                                date_updated: new Date(),
                            },
                            spouse: {
                                date_updated: new Date(),
                                cell_number: spouseCellNumber,
                                home_number: spouseHomeNumber,
                                name: spouseName,
                                surname: spouseSurname,
                                work_number: spouseWorkNumber,
                            },
                        })];
                case 1:
                    personalUpdated = _a.sent();
                    if (!(personalUpdated === null || personalUpdated === void 0 ? void 0 : personalUpdated.success)) return [3 /*break*/, 3];
                    //Update Loan Details
                    return [4 /*yield*/, (0, application_1.update_loan_application)({
                            uid: loan_id,
                            home_address: {
                                full_address: homeAddress,
                                date_updated: new Date(),
                            },
                            postal_address: postalAddress,
                            personal_complete: true,
                            spouse: {
                                cell_number: spouseCellNumber,
                                home_number: spouseHomeNumber,
                                name: spouseName,
                                surname: spouseSurname,
                                work_number: spouseWorkNumber,
                            },
                        }).then(function (response) {
                            if (response.success) {
                                setIsSaving(false);
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FAMILY_DETAILS, "/").concat(loan_id));
                                //navigate next
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            }
                        })];
                case 2:
                    //Update Loan Details
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var previousStep = function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_START, "/").concat(loan_id));
    };
    var clearErrorMessages = function () {
        setSpouseNameMessage("");
        setSpouseSurnameMessage("");
        setSpouseCellNumberMessage("");
        setSpouseHomeNumberMessage("");
        setSpouseWorkNumberMessage("");
        setNameMessage("");
        setSurnameMessage("");
        setIdMessage("");
        setPostalAddressMessage("");
        setHomeAddressMessage("");
        setGenderMessage("");
        setMarriageTypeMessage("");
    };
    var validateDetails = function () {
        clearErrorMessages();
        var messageCount = 0;
        if (!name) {
            messageCount++;
            setNameMessage("Name is required.");
        }
        if (!surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!idNumber) {
            messageCount++;
            setIdMessage("ID number is required.");
        }
        // if (!postalAddress) {
        //   messageCount++;
        //   setPostalAddressMessage("Postal address is required.");
        // }
        if (!homeAddress) {
            messageCount++;
            setHomeAddressMessage("Home address is required.");
        }
        if (!gender) {
            messageCount++;
            setGenderMessage("Gender is required.");
        }
        if (married) {
            if (!spouseName) {
                messageCount++;
                setSpouseNameMessage("Name is required.");
            }
            if (!spouseSurname) {
                messageCount++;
                setSpouseSurnameMessage("Surname is required.");
            }
            if (!(spouseCellNumber === null || spouseCellNumber === void 0 ? void 0 : spouseCellNumber.phone_number)) {
                messageCount++;
                setSpouseCellNumberMessage("Cell number is required.");
            }
            else {
                if (!doshx_controls_web_1.Utilities.isValidPhoneNumber(spouseCellNumber === null || spouseCellNumber === void 0 ? void 0 : spouseCellNumber.phone_number, spouseCellNumber === null || spouseCellNumber === void 0 ? void 0 : spouseCellNumber.country_abbreviation)) {
                    messageCount++;
                    setSpouseCellNumberMessage("Invalid Number.");
                }
            }
            if (spouseHomeNumber === null || spouseHomeNumber === void 0 ? void 0 : spouseHomeNumber.phone_number) {
                var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(spouseHomeNumber === null || spouseHomeNumber === void 0 ? void 0 : spouseHomeNumber.phone_number, spouseHomeNumber === null || spouseHomeNumber === void 0 ? void 0 : spouseHomeNumber.country_abbreviation);
                if (!isValidPhoneNumber) {
                    messageCount++;
                    setSpouseHomeNumberMessage("Invalid Number.");
                }
            }
            if (spouseWorkNumber === null || spouseWorkNumber === void 0 ? void 0 : spouseWorkNumber.phone_number) {
                var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(spouseWorkNumber === null || spouseWorkNumber === void 0 ? void 0 : spouseWorkNumber.phone_number, spouseWorkNumber === null || spouseWorkNumber === void 0 ? void 0 : spouseWorkNumber.country_abbreviation);
                if (!isValidPhoneNumber) {
                    messageCount++;
                    setSpouseWorkNumberMessage("Invalid Number.");
                }
            }
            if (!marriageType) {
                messageCount++;
                setMarriageTypeMessage("Please select marriage type.");
            }
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "#",
                        title: "Loan  Aplication",
                    },
                    {
                        title: "Personal Details",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Personal Details", textColor: "text-black", bold: true })),
                    react_1.default.createElement("div", { className: "flex flex-col md:flex-row gap-3" },
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", disabled: (user === null || user === void 0 ? void 0 : user.SumSubApproved) ? true : false, value: name, size: "small", errorText: nameMessage, onChange: function (v) { return setName(v); } })),
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", disabled: (user === null || user === void 0 ? void 0 : user.SumSubApproved) ? true : false, value: surname, size: "small", errorText: surnameMessage, onChange: function (v) { return setSurname(v); } }))),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "ID Number", disabled: (user === null || user === void 0 ? void 0 : user.SumSubApproved) ? true : false, value: idNumber, size: "small", errorText: idMessage, onChange: function (v) { return setIdNumber(v); } }),
                    react_1.default.createElement("div", { className: " flex flex-col gap-4 bg-gray-100 rounded-md p-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Please Select Your Physical Address", textColor: "text-black", size: "small", bold: true }),
                        react_1.default.createElement("div", { className: "flex flex-col gap-1 py-2" }, addressList.map(function (address, index) {
                            return (react_1.default.createElement("div", { key: index, className: "".concat(index == 0 ? "" : "border-t border-borders", " flex flex-row justify-center items-center cursor-pointer"), onClick: function () {
                                    setHomeAddress(address === null || address === void 0 ? void 0 : address.full_address);
                                } },
                                react_1.default.createElement("div", { className: "flex-1 flex flex-row items-center gap-2 pt-2" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address === null || address === void 0 ? void 0 : address.label, bold: true, textColor: "text-black", size: "xsmall" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "\u2022", size: "xsmall" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address === null || address === void 0 ? void 0 : address.full_address, size: "xsmall" })),
                                react_1.default.createElement("div", { className: "pt-2" }, (address === null || address === void 0 ? void 0 : address.full_address) == homeAddress ? (react_1.default.createElement("div", { className: "flex flex-row gap-1  text-secondary" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faCheckCircle }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Selected", textColor: "text-secondary", size: "xsmall", bold: true }),
                                    " ")) : (""))));
                        })),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Or Add a New Physical Address", textColor: "text-black", size: "small", bold: true }),
                        react_1.default.createElement("div", { className: "flex flex-col gap-1" },
                            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { label: "Physical Address", letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, required: true, placeholder: homeAddress, size: "small", errorText: homeAddressMessage, onSelectionChange: function (value) {
                                    setHomeAddress(value.fullAddress);
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: homeAddress, size: "xsmall" }))),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Postal Address", textColor: "text-black", value: postalAddress, size: "small", errorText: postalAddressMessage, onChange: function (v) { return setPostalAddress(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                        ], errorTextColor: "text-red-500", label: "Gender", size: "small", value: gender, errorText: genderMessage, onChange: function (v) {
                            setGender(v);
                        } })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Marital Status", textColor: "text-black", bold: true })),
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Married", value: married, onChange: function () {
                        setMarried(!married);
                    } }),
                married ? (react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Marriage Type", textColor: "text-black", bold: true })),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Traditional", value: "Traditional" },
                            { label: "In Community Of Property", value: "InCommunityOfProperty" },
                            { label: "Out Of Community Of Property", value: "OutOfCommunityOfProperty" },
                        ], errorTextColor: "text-red-500", label: "Marriage Type", size: "small", value: marriageType, errorText: marriageTypeMessage, onChange: function (v) {
                            setMarriageType(v);
                        } }),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Spouse Details", textColor: "text-black", bold: true })),
                    react_1.default.createElement("div", { className: "flex flex-col md:flex-row gap-3" },
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: spouseName, size: "small", errorText: spouseNameMessage, onChange: function (v) { return setSpouseName(v); } })),
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: spouseSurname, size: "small", errorText: spouseSurnameMessage, onChange: function (v) { return setSpouseSurname(v); } }))),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Cell Number", value: spouseCellNumber === null || spouseCellNumber === void 0 ? void 0 : spouseCellNumber.phone_number, size: "small", errorText: spouseCellNumberMessage, defaultCountryCode: (_a = spouseCellNumber === null || spouseCellNumber === void 0 ? void 0 : spouseCellNumber.country_abbreviation) !== null && _a !== void 0 ? _a : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setSpouseCellNumber(__assign(__assign({}, spouseCellNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setSpouseCellNumber({
                                    phone_number: spouseCellNumber === null || spouseCellNumber === void 0 ? void 0 : spouseCellNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Work Number", value: (_b = spouseWorkNumber === null || spouseWorkNumber === void 0 ? void 0 : spouseWorkNumber.phone_number) !== null && _b !== void 0 ? _b : "", size: "small", errorText: spouseWorkNumberMessage, defaultCountryCode: (_c = spouseWorkNumber === null || spouseWorkNumber === void 0 ? void 0 : spouseWorkNumber.country_abbreviation) !== null && _c !== void 0 ? _c : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setSpouseWorkNumber(__assign(__assign({}, spouseWorkNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setSpouseWorkNumber({
                                    phone_number: spouseWorkNumber === null || spouseWorkNumber === void 0 ? void 0 : spouseWorkNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Home Number", value: (_d = spouseHomeNumber === null || spouseHomeNumber === void 0 ? void 0 : spouseHomeNumber.phone_number) !== null && _d !== void 0 ? _d : "", size: "small", errorText: "", defaultCountryCode: (_e = spouseHomeNumber === null || spouseHomeNumber === void 0 ? void 0 : spouseHomeNumber.country_abbreviation) !== null && _e !== void 0 ? _e : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setSpouseHomeNumber(__assign(__assign({}, spouseHomeNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setSpouseHomeNumber({
                                    phone_number: spouseHomeNumber === null || spouseHomeNumber === void 0 ? void 0 : spouseHomeNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }))) : null,
                react_1.default.createElement("div", { className: "flex justify-center gap-4 " },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", loading: isSaving, backgroundColor: "bg-primary", size: "small", startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            previousStep();
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save & Proceed", loading: isSaving, backgroundColor: "bg-secondary", size: "small", endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                            savePersonalDetails();
                        } }))))));
};
exports.PersonalDetailsPage = PersonalDetailsPage;
