"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var main_1 = require("../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var application_1 = require("../../../../api/calls/loan/application");
var get_loan_1 = require("../../../../api/calls/loan/get_loan");
var firebase_file_upload_1 = require("../../../../api/calls/docs/firebase_file_upload");
var steps_1 = require("./constants/steps");
var route_names_1 = require("../../../../constants/route_names");
var DocumentsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var _a = (0, react_1.useState)(), bankStatements = _a[0], setBankStatements = _a[1];
    var _b = (0, react_1.useState)(), payslip = _b[0], setPayslip = _b[1];
    var _c = (0, react_1.useState)(""), bankStatementsError = _c[0], setBankStatementsError = _c[1];
    var _d = (0, react_1.useState)(""), payslipError = _d[0], setPayslipError = _d[1];
    //
    var _e = (0, react_1.useState)(false), isSaving = _e[0], setIsSaving = _e[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        if (loan_id) {
            (0, get_loan_1.getLoan)({ loan_id: loan_id }).then(function (response) {
                if (response.success) {
                    var loanData = response.content.data();
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    }, [loan_id]);
    var saveDocuments = function () { return __awaiter(void 0, void 0, void 0, function () {
        var latestPayslip_, bankStatements_, _i, bankStatements_1, statement, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateDetails()) return [3 /*break*/, 8];
                    setIsSaving(true);
                    latestPayslip_ = "";
                    bankStatements_ = [];
                    return [4 /*yield*/, (0, firebase_file_upload_1.uploadFileToFirebase)(payslip)];
                case 1:
                    //Upoad Documents and get URLs
                    latestPayslip_ = _a.sent();
                    _i = 0, bankStatements_1 = bankStatements;
                    _a.label = 2;
                case 2:
                    if (!(_i < bankStatements_1.length)) return [3 /*break*/, 6];
                    statement = bankStatements_1[_i];
                    return [4 /*yield*/, (0, firebase_file_upload_1.uploadFileToFirebase)(statement)];
                case 3:
                    data = _a.sent();
                    return [4 /*yield*/, bankStatements_.push(data)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    _i++;
                    return [3 /*break*/, 2];
                case 6: 
                // Update Loan Details
                return [4 /*yield*/, (0, application_1.update_loan_application)({
                        uid: loan_id,
                        latest_payslip: latestPayslip_,
                        bank_statements: bankStatements_,
                        docs_complete: true,
                    }).then(function (response) {
                        if (response.success) {
                            setIsSaving(false);
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                            navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/agreement-of-sale/").concat(loan_id));
                            //navigate next
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                        }
                    })];
                case 7:
                    // Update Loan Details
                    _a.sent();
                    _a.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var previousStep = function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FINANCIAL_DETAILS, "/").concat(loan_id));
    };
    var validateDetails = function () {
        var messageCount = 0;
        setBankStatementsError("");
        setPayslipError("");
        if (!bankStatements || bankStatements.length == 0) {
            messageCount++;
            setBankStatementsError("Please upload your 3 months bank statements.");
        }
        if (!payslip) {
            messageCount++;
            setPayslipError("Please upload a latest payslip");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "#",
                        title: "Loan Application",
                    },
                    {
                        title: "Required Documents",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Required Documents", textColor: "text-black", bold: true })),
                    react_1.default.createElement("div", { className: "flex flex-col md:flex-row gap-3" },
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.FileInputControl, { placeholder: "Select latest payslip", label: "Payslip", size: "small", file: payslip, onChange: function (file) {
                                    setPayslip(file);
                                }, errorText: payslipError })),
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.MultiFileInputControl, { title: "Select 3 months bank statement", files: bankStatements, size: "small", previewFiles: false, colors: {
                                    titleColor: "text-black",
                                    borderColor: "border-transparent",
                                    fileBorderColor: "border-primary",
                                    buttonColor: "bg-primary",
                                    textColor: "text-black",
                                    placeholderColor: "text-black",
                                    backgroundColor: "bg-gray-100",
                                }, onChange: function (bankStatements) {
                                    setBankStatements(bankStatements);
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bankStatementsError, size: "xsmall", textColor: "text-red-500" })))),
                react_1.default.createElement("div", { className: "flex justify-center gap-4 " },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", loading: isSaving, backgroundColor: "bg-primary", size: "small", startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            previousStep();
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Proceed", backgroundColor: "bg-secondary", size: "small", endIcon: pro_solid_svg_icons_1.faArrowRight, loading: isSaving, onClick: function () {
                            saveDocuments();
                        } }))))));
};
exports.DocumentsPage = DocumentsPage;
