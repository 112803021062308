"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetPasswordPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var reset_password_1 = require("../../../api/calls/user/authentication/reset_password");
var ResetPasswordPage = function () {
    var params = (0, react_router_dom_1.useParams)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var resetCode = params.resetCode;
    var _a = (0, react_1.useState)(""), password = _a[0], setPassword = _a[1];
    var _b = (0, react_1.useState)(""), rePassword = _b[0], setRePassword = _b[1];
    var _c = (0, react_1.useState)(""), passwordError = _c[0], setPasswordError = _c[1];
    var _d = (0, react_1.useState)(""), rePasswordError = _d[0], setRePasswordError = _d[1];
    var _e = (0, react_1.useState)(""), errorText = _e[0], setErrorText = _e[1];
    var _f = (0, react_1.useState)(""), successText = _f[0], setSuccessText = _f[1];
    var _g = (0, react_1.useState)(false), isButtonLoading = _g[0], setIsButtonLoading = _g[1];
    (0, react_1.useEffect)(function () {
        if (resetCode) {
        }
    }, [resetCode]);
    var validate = function () {
        setPasswordError("");
        setRePasswordError("");
        setErrorText("");
        setSuccessText("");
        var messageCount = 0;
        if (!password) {
            messageCount++;
            setPasswordError("Password is required!");
        }
        if (!rePassword) {
            messageCount++;
            setRePasswordError("Password is required!");
        }
        if (password && rePassword) {
            if (password != rePassword) {
                messageCount++;
                setRePasswordError("Passwords do not match email");
            }
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement("div", { className: "relative" },
            react_1.default.createElement("div", { className: "flex flex-row justify-center" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
                    react_1.default.createElement("div", { className: "flex flex-col items-center" },
                        react_1.default.createElement("div", { className: "w-[400px] md:w-[600px] lg:w-[800px] flex flex-col gap-y-4 p-8" },
                            react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "bg-white shadow-md", variant: "filled", addPadding: false },
                                react_1.default.createElement("div", { className: "flex flex-col gap-4  p-8 " },
                                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { size: "small", label: "New Password", type: "password", value: password, onChange: function (v) { return setPassword(v); }, errorText: passwordError, required: true }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { size: "small", label: "Confirm New Password", type: "password", value: rePassword, onChange: function (v) { return setRePassword(v); }, errorText: rePasswordError, required: true })),
                                    react_1.default.createElement("div", { className: "flex gap-1" },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: successText, size: "medium", textColor: "text-success" }),
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: errorText, size: "medium", textColor: "text-red-500" })),
                                    react_1.default.createElement("div", { className: "flex flex-row justify-start" },
                                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { size: "small", label: "Reset Password", backgroundColor: "bg-secondary" //
                                            , loading: isButtonLoading, onClick: function () {
                                                if (validate()) {
                                                    setIsButtonLoading(true);
                                                    (0, reset_password_1.reset_password)({ code: resetCode !== null && resetCode !== void 0 ? resetCode : "", password: password }).then(function (response) {
                                                        if (response.success) {
                                                            setSuccessText(response.message);
                                                            setIsButtonLoading(response.content.is_loading);
                                                            if (!user.uid) {
                                                                dispatch((0, main_1.setDrawerPage)("login"));
                                                                dispatch((0, main_1.setDrawerOpen)(true));
                                                            }
                                                        }
                                                        else {
                                                            setErrorText(response.message);
                                                            setIsButtonLoading(response.content.is_loading);
                                                        }
                                                    });
                                                }
                                            } })))))))))));
};
exports.ResetPasswordPage = ResetPasswordPage;
