"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NextOfKinDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var update_advanced_user_details_1 = require("../../../api/calls/user/update_advanced_user_details");
var main_1 = require("../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_user_details_1 = require("../../../api/calls/user/get_user_details");
var functions_1 = require("../../../constants/functions");
var NextOfKinDetailsPage = function () {
    var _a, _b, _c, _d, _e, _f;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _g = (0, react_1.useState)(""), friendName = _g[0], setFriendName = _g[1];
    var _h = (0, react_1.useState)(""), friendSurname = _h[0], setFriendSurname = _h[1];
    var _j = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), friendCellNumber = _j[0], setFriendCellNumber = _j[1];
    var _k = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), friendHomeNumber = _k[0], setFriendHomeNumber = _k[1];
    var _l = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), friendWorkNumber = _l[0], setFriendWorkNumber = _l[1];
    var _m = (0, react_1.useState)(""), friendNameMessage = _m[0], setFriendNameMessage = _m[1];
    var _o = (0, react_1.useState)(""), friendSurnameMessage = _o[0], setFriendSurnameMessage = _o[1];
    var _p = (0, react_1.useState)(""), friendCellNumberMessage = _p[0], setFriendCellNumberMessage = _p[1];
    var _q = (0, react_1.useState)(""), friendHomeNumberMessage = _q[0], setFriendHomeNumberMessage = _q[1];
    var _r = (0, react_1.useState)(""), friendWorkNumberMessage = _r[0], setFriendWorkNumberMessage = _r[1];
    var _s = (0, react_1.useState)(""), nextKinHomeNumberMessage = _s[0], setNextKinHomeNumberMessage = _s[1];
    var _t = (0, react_1.useState)(""), nextKinWorkNumberMessage = _t[0], setNextKinWorkNumberMessage = _t[1];
    var _u = (0, react_1.useState)(""), nextKinNameMessage = _u[0], setNextKinNameMessage = _u[1];
    var _v = (0, react_1.useState)(""), nextKinSurnameMessage = _v[0], setNextKinSurnameMessage = _v[1];
    var _w = (0, react_1.useState)(""), nextKinCellNumberMessage = _w[0], setNextKinCellNumberMessage = _w[1];
    var _x = (0, react_1.useState)(""), nextKinRelationshipMessage = _x[0], setNextKinRelationshipMessage = _x[1];
    var _y = (0, react_1.useState)(""), nextKinName = _y[0], setNextKinName = _y[1];
    var _z = (0, react_1.useState)(""), nextKinSurname = _z[0], setNextKinSurname = _z[1];
    var _0 = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), nextKinCellNumber = _0[0], setNextKinCellNumber = _0[1];
    var _1 = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), nextKinHomeNumber = _1[0], setNextKinHomeNumber = _1[1];
    var _2 = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), nextKinWorkNumber = _2[0], setNextKinWorkNumber = _2[1];
    var _3 = (0, react_1.useState)(""), nextKinRelationship = _3[0], setNextKinRelationship = _3[1];
    var _4 = (0, react_1.useState)(false), isSaving = _4[0], setIsSaving = _4[1];
    var _5 = (0, react_1.useState)(true), isLoadingData = _5[0], setIsLoadingData = _5[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        if (user.uid) {
            (0, get_user_details_1.get_user_details)().then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                var _a, _b, _c, _d, _e, _f;
                var _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                return __generator(this, function (_4) {
                    switch (_4.label) {
                        case 0:
                            if (!response.success) return [3 /*break*/, 7];
                            setFriendName((_h = (_g = response === null || response === void 0 ? void 0 : response.content) === null || _g === void 0 ? void 0 : _g.friend) === null || _h === void 0 ? void 0 : _h.name);
                            setFriendSurname((_k = (_j = response === null || response === void 0 ? void 0 : response.content) === null || _j === void 0 ? void 0 : _j.friend) === null || _k === void 0 ? void 0 : _k.surname);
                            _a = setFriendCellNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_m = (_l = response === null || response === void 0 ? void 0 : response.content) === null || _l === void 0 ? void 0 : _l.friend) === null || _m === void 0 ? void 0 : _m.cell_number)];
                        case 1:
                            _a.apply(void 0, [_4.sent()]);
                            _b = setFriendHomeNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_p = (_o = response === null || response === void 0 ? void 0 : response.content) === null || _o === void 0 ? void 0 : _o.friend) === null || _p === void 0 ? void 0 : _p.home_number)];
                        case 2:
                            _b.apply(void 0, [_4.sent()]);
                            _c = setFriendWorkNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_r = (_q = response === null || response === void 0 ? void 0 : response.content) === null || _q === void 0 ? void 0 : _q.friend) === null || _r === void 0 ? void 0 : _r.work_number)];
                        case 3:
                            _c.apply(void 0, [_4.sent()]);
                            setNextKinName((_t = (_s = response === null || response === void 0 ? void 0 : response.content) === null || _s === void 0 ? void 0 : _s.family_member) === null || _t === void 0 ? void 0 : _t.name);
                            setNextKinSurname((_v = (_u = response === null || response === void 0 ? void 0 : response.content) === null || _u === void 0 ? void 0 : _u.family_member) === null || _v === void 0 ? void 0 : _v.surname);
                            _d = setNextKinCellNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_x = (_w = response === null || response === void 0 ? void 0 : response.content) === null || _w === void 0 ? void 0 : _w.family_member) === null || _x === void 0 ? void 0 : _x.cell_number)];
                        case 4:
                            _d.apply(void 0, [_4.sent()]);
                            _e = setNextKinHomeNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_z = (_y = response === null || response === void 0 ? void 0 : response.content) === null || _y === void 0 ? void 0 : _y.family_member) === null || _z === void 0 ? void 0 : _z.home_number)];
                        case 5:
                            _e.apply(void 0, [_4.sent()]);
                            _f = setNextKinWorkNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_1 = (_0 = response === null || response === void 0 ? void 0 : response.content) === null || _0 === void 0 ? void 0 : _0.family_member) === null || _1 === void 0 ? void 0 : _1.work_number)];
                        case 6:
                            _f.apply(void 0, [_4.sent()]);
                            setNextKinRelationship((_3 = (_2 = response === null || response === void 0 ? void 0 : response.content) === null || _2 === void 0 ? void 0 : _2.family_member) === null || _3 === void 0 ? void 0 : _3.relationship);
                            setIsLoadingData(false);
                            return [3 /*break*/, 8];
                        case 7:
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            setIsLoadingData(false);
                            _4.label = 8;
                        case 8: return [2 /*return*/];
                    }
                });
            }); });
        }
        //
    }, [user]);
    var saveNextKinDetails = function () {
        if (validateDetails()) {
            setIsSaving(true);
            var family_member = {
                date_updated: new Date(),
                cell_number: nextKinCellNumber,
                home_number: nextKinHomeNumber,
                name: nextKinName,
                relationship: nextKinRelationship,
                surname: nextKinSurname,
                work_number: nextKinWorkNumber,
            };
            var friend = {
                date_updated: new Date(),
                cell_number: friendCellNumber,
                home_number: friendHomeNumber,
                name: friendName,
                surname: friendSurname,
                work_number: friendWorkNumber,
            };
            (0, update_advanced_user_details_1.update_advanced_user_details)({
                friend: friend,
                family_member: family_member,
            }).then(function (response) {
                setIsSaving(false);
                if (response.success) {
                    navigate("/advanced-personal-details/complete");
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    };
    var clearErrorMessages = function () {
        setFriendNameMessage("");
        setFriendSurnameMessage("");
        setFriendCellNumberMessage("");
        setFriendHomeNumberMessage("");
        setFriendWorkNumberMessage("");
        setNextKinHomeNumberMessage("");
        setNextKinWorkNumberMessage("");
        setNextKinNameMessage("");
        setNextKinSurnameMessage("");
        setNextKinCellNumberMessage("");
        setNextKinRelationshipMessage("");
    };
    var validateDetails = function () {
        clearErrorMessages();
        var messageCount = 0;
        // if (!nextKinName) {
        //   messageCount++;
        //   setNextKinNameMessage("Name is required.");
        // }
        // if (!nextKinSurname) {
        //   messageCount++;
        //   setNextKinSurnameMessage("Surname is required.");
        // }
        // if (!nextKinCellNumber.phone_number) {
        //   messageCount++;
        //   setNextKinCellNumberMessage("Cell number is required.");
        // } else {
        //   if (!Utilities.isValidPhoneNumber(nextKinCellNumber.phone_number, nextKinCellNumber?.country_abbreviation)) {
        //     messageCount++;
        //     setNextKinCellNumberMessage("Invalid Number.");
        //   }
        // }
        if (nextKinCellNumber.phone_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(nextKinCellNumber === null || nextKinCellNumber === void 0 ? void 0 : nextKinCellNumber.phone_number, nextKinCellNumber === null || nextKinCellNumber === void 0 ? void 0 : nextKinCellNumber.country_abbreviation);
            if (!isValidPhoneNumber) {
                messageCount++;
                setNextKinCellNumberMessage("Invalid Number.");
            }
        }
        if (nextKinHomeNumber.phone_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(nextKinHomeNumber === null || nextKinHomeNumber === void 0 ? void 0 : nextKinHomeNumber.phone_number, nextKinHomeNumber === null || nextKinHomeNumber === void 0 ? void 0 : nextKinHomeNumber.country_abbreviation);
            if (!isValidPhoneNumber) {
                messageCount++;
                setNextKinHomeNumberMessage("Invalid Number.");
            }
        }
        if (nextKinWorkNumber.phone_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(nextKinWorkNumber === null || nextKinWorkNumber === void 0 ? void 0 : nextKinWorkNumber.phone_number, nextKinWorkNumber === null || nextKinWorkNumber === void 0 ? void 0 : nextKinWorkNumber.country_abbreviation);
            if (!isValidPhoneNumber) {
                messageCount++;
                setNextKinWorkNumberMessage("Invalid Number.");
            }
        }
        // if (!friendName) {
        //   messageCount++;
        //   setFriendNameMessage("Name is required.");
        // }
        // if (!friendSurname) {
        //   messageCount++;
        //   setFriendSurnameMessage("Surname is required.");
        // }
        // if (!friendCellNumber.phone_number) {
        //   messageCount++;
        //   setFriendCellNumberMessage("Cell number is required.");
        // } else {
        //   if (!Utilities.isValidPhoneNumber(friendCellNumber.phone_number, friendCellNumber?.country_abbreviation)) {
        //     messageCount++;
        //     setFriendCellNumberMessage("Invalid Number.");
        //   }
        // }
        if (friendCellNumber.phone_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(friendCellNumber === null || friendCellNumber === void 0 ? void 0 : friendCellNumber.phone_number, friendCellNumber === null || friendCellNumber === void 0 ? void 0 : friendCellNumber.country_abbreviation);
            if (!isValidPhoneNumber) {
                messageCount++;
                setFriendCellNumberMessage("Invalid Number.");
            }
        }
        if (friendHomeNumber.phone_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(friendHomeNumber === null || friendHomeNumber === void 0 ? void 0 : friendHomeNumber.phone_number, friendHomeNumber === null || friendHomeNumber === void 0 ? void 0 : friendHomeNumber.country_abbreviation);
            if (!isValidPhoneNumber) {
                messageCount++;
                setFriendHomeNumberMessage("Invalid Number.");
            }
        }
        if (friendWorkNumber.phone_number) {
            var isValidPhoneNumber = doshx_controls_web_1.Utilities.isValidPhoneNumber(friendWorkNumber === null || friendWorkNumber === void 0 ? void 0 : friendWorkNumber.phone_number, friendWorkNumber === null || friendWorkNumber === void 0 ? void 0 : friendWorkNumber.country_abbreviation);
            if (!isValidPhoneNumber) {
                messageCount++;
                setFriendWorkNumberMessage("Invalid Number.");
            }
        }
        // if (!nextKinRelationship) {
        //   messageCount++;
        //   setNextKinRelationshipMessage("Relationship is required.");
        // }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "#",
                        title: "Advanced Personal Details",
                    },
                    {
                        title: "Family Member & Friend Contact Details",
                        active: true,
                    },
                ] }),
            isLoadingData ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Advanced Personal Details", textColor: "text-black", bold: true })),
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Family Member Contact Details", textColor: "text-black", bold: true })),
                    react_1.default.createElement("div", { className: "flex flex-col md:flex-row gap-3" },
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: nextKinName, size: "small", errorText: nextKinNameMessage, onChange: function (v) { return setNextKinName(v); } })),
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: nextKinSurname, size: "small", errorText: nextKinSurnameMessage, onChange: function (v) { return setNextKinSurname(v); } }))),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Cell Number", value: nextKinCellNumber === null || nextKinCellNumber === void 0 ? void 0 : nextKinCellNumber.phone_number, size: "small", errorText: nextKinCellNumberMessage, defaultCountryCode: (_a = nextKinCellNumber === null || nextKinCellNumber === void 0 ? void 0 : nextKinCellNumber.country_abbreviation) !== null && _a !== void 0 ? _a : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setNextKinCellNumber(__assign(__assign({}, nextKinCellNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setNextKinCellNumber({
                                    phone_number: nextKinCellNumber === null || nextKinCellNumber === void 0 ? void 0 : nextKinCellNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Work Number", value: nextKinWorkNumber === null || nextKinWorkNumber === void 0 ? void 0 : nextKinWorkNumber.phone_number, size: "small", errorText: nextKinWorkNumberMessage, defaultCountryCode: (_b = nextKinWorkNumber === null || nextKinWorkNumber === void 0 ? void 0 : nextKinWorkNumber.country_abbreviation) !== null && _b !== void 0 ? _b : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setNextKinWorkNumber(__assign(__assign({}, nextKinWorkNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setNextKinWorkNumber({
                                    phone_number: nextKinWorkNumber === null || nextKinWorkNumber === void 0 ? void 0 : nextKinWorkNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Home Number", value: nextKinHomeNumber === null || nextKinHomeNumber === void 0 ? void 0 : nextKinHomeNumber.phone_number, size: "small", errorText: nextKinHomeNumberMessage, defaultCountryCode: (_c = nextKinHomeNumber === null || nextKinHomeNumber === void 0 ? void 0 : nextKinHomeNumber.country_abbreviation) !== null && _c !== void 0 ? _c : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setNextKinHomeNumber(__assign(__assign({}, nextKinHomeNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setNextKinHomeNumber({
                                    phone_number: nextKinHomeNumber === null || nextKinHomeNumber === void 0 ? void 0 : nextKinHomeNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Father", value: "Father" },
                            { label: "Brother", value: "Brother" },
                            { label: "Mother", value: "Mother" },
                            { label: "Daughter", value: "Daughter" },
                            { label: "Son", value: "Son" },
                            { label: "Spouse", value: "Spouse" },
                            { label: "Partner", value: "Partner" },
                            { label: "Aunt", value: "Aunt" },
                            { label: "Niece", value: "Niece" },
                            { label: "Nephew", value: "Nephew" },
                            { label: "Sister", value: "Sister" },
                            { label: "Uncle", value: "Uncle" },
                            { label: "Cousin", value: "Cousin" },
                            { label: "Grandmother", value: "Grandmother" },
                            { label: "Grandfather", value: "Grandfather" },
                            { label: "Granddaughter", value: "Granddaughter" },
                            { label: "Grandson", value: "Grandson" },
                            { label: "Stepmother", value: "Stepmother" },
                            { label: "Stepfather", value: "Stepfather" },
                            { label: "Stepdaughter", value: "Stepdaughter" },
                            { label: "Stepson", value: "Stepson" },
                        ], errorTextColor: "text-red-500", label: "Relationship", size: "small", value: nextKinRelationship, errorText: nextKinRelationshipMessage, onChange: function (v) {
                            setNextKinRelationship(v);
                        } })),
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Friend Contact Details", textColor: "text-black", bold: true })),
                    react_1.default.createElement("div", { className: "flex flex-col md:flex-row gap-3" },
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: friendName, size: "small", errorText: friendNameMessage, onChange: function (v) { return setFriendName(v); } })),
                        react_1.default.createElement("div", { className: "w-full" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: friendSurname, size: "small", errorText: friendSurnameMessage, onChange: function (v) { return setFriendSurname(v); } }))),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Cell Number", value: friendCellNumber === null || friendCellNumber === void 0 ? void 0 : friendCellNumber.phone_number, size: "small", errorText: friendCellNumberMessage, defaultCountryCode: (_d = friendCellNumber === null || friendCellNumber === void 0 ? void 0 : friendCellNumber.country_abbreviation) !== null && _d !== void 0 ? _d : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setFriendCellNumber(__assign(__assign({}, friendCellNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setFriendCellNumber({
                                    phone_number: friendCellNumber === null || friendCellNumber === void 0 ? void 0 : friendCellNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Work Number", value: friendWorkNumber === null || friendWorkNumber === void 0 ? void 0 : friendWorkNumber.phone_number, size: "small", errorText: friendWorkNumberMessage, defaultCountryCode: (_e = friendWorkNumber === null || friendWorkNumber === void 0 ? void 0 : friendWorkNumber.country_abbreviation) !== null && _e !== void 0 ? _e : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setFriendWorkNumber(__assign(__assign({}, friendWorkNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setFriendWorkNumber({
                                    phone_number: friendWorkNumber === null || friendWorkNumber === void 0 ? void 0 : friendWorkNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Home Number", value: friendHomeNumber === null || friendHomeNumber === void 0 ? void 0 : friendHomeNumber.phone_number, size: "small", errorText: friendHomeNumberMessage, defaultCountryCode: (_f = friendHomeNumber === null || friendHomeNumber === void 0 ? void 0 : friendHomeNumber.country_abbreviation) !== null && _f !== void 0 ? _f : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setFriendHomeNumber(__assign(__assign({}, friendHomeNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setFriendHomeNumber({
                                    phone_number: friendHomeNumber === null || friendHomeNumber === void 0 ? void 0 : friendHomeNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); } })),
                react_1.default.createElement("div", { className: "flex justify-center gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", backgroundColor: "bg-primary", size: "small", loading: isSaving, startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            navigate("/advanced-personal-details/personal-details");
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Proceed", backgroundColor: "bg-secondary", size: "small", endIcon: pro_solid_svg_icons_1.faArrowRight, loading: isSaving, onClick: function () {
                            saveNextKinDetails();
                        } })))))));
};
exports.NextOfKinDetailsPage = NextOfKinDetailsPage;
