"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryProductsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var products_1 = require("../../../api/calls/product/products");
var main_1 = require("../../../reducers/slices/main");
var selection_1 = require("../../../reducers/slices/selection");
var add_product_thunk_1 = require("../../../reducers/slices/basket/thunks/add_product_thunk");
var symbols_1 = require("../../../constants/symbols");
var favourite_product_1 = require("../../../api/calls/product/favourite_product");
var get_favourite_products_1 = require("../../../api/calls/product/get_favourite_products");
var CategoryProductsPage = function () {
    var params = (0, react_router_dom_1.useParams)();
    var level = params.level, categoryId = params.categoryId, categoryName = params.categoryName;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)([]), products = _a[0], setProducts = _a[1];
    var _b = (0, react_1.useState)(true), isLoadingProducts = _b[0], setIsloadingProducts = _b[1];
    var _c = (0, react_1.useState)([]), favouriteProducts = _c[0], setFavouriteProducts = _c[1];
    var _d = (0, react_1.useState)(1), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = (0, react_1.useState)(0), totalRecords = _e[0], setTotalRecords = _e[1];
    var _f = (0, react_1.useState)(10), recordsPerPage = _f[0], setRecordsPerPage = _f[1];
    (0, react_1.useEffect)(function () {
        init();
    }, [user]);
    var init = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(user === null || user === void 0 ? void 0 : user.uid)) return [3 /*break*/, 1];
                    (0, get_favourite_products_1.get_favourite_products)().then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.success) return [3 /*break*/, 2];
                                    return [4 /*yield*/, setFavouriteProducts(response.content)];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, setFavouriteProducts([])];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchProducts = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, products_1.getProducts)({
                        category_id: categoryId,
                        page: currentPage.toString(),
                        size: recordsPerPage.toString(),
                    }).then(function (response) {
                        if (response.success) {
                            var list = response.content.product_list;
                            setTotalRecords(parseInt(response.content.count));
                            setProducts(list.map(function (product) {
                                return {
                                    _id: product._id,
                                    title: product.title,
                                    image: product === null || product === void 0 ? void 0 : product.image_url,
                                    loaderColor: "text-primary",
                                    shadowColor: "shadow-shadows",
                                    borderColor: "border-none",
                                    amount: product === null || product === void 0 ? void 0 : product.price,
                                    amountColor: "text-secondary",
                                    discountLabel: (product === null || product === void 0 ? void 0 : product.on_promotion) ? "ON PROMOTION" : "",
                                    discountAmount: (product === null || product === void 0 ? void 0 : product.on_promotion) ? product === null || product === void 0 ? void 0 : product.promotion_price : null,
                                    pid: product === null || product === void 0 ? void 0 : product.pid,
                                    status: product === null || product === void 0 ? void 0 : product.status,
                                    sku: product === null || product === void 0 ? void 0 : product.sku,
                                    sub_title: product === null || product === void 0 ? void 0 : product.sub_title,
                                    description: product === null || product === void 0 ? void 0 : product.description,
                                    instruction: product === null || product === void 0 ? void 0 : product.instruction,
                                    estimated_delivery_time: product === null || product === void 0 ? void 0 : product.estimated_delivery_time,
                                    promotion_price: product === null || product === void 0 ? void 0 : product.promotion_price.toString(),
                                    on_promotion: product === null || product === void 0 ? void 0 : product.on_promotion,
                                    outOfStock: (product === null || product === void 0 ? void 0 : product.status) != "AVAILABLE" ? true : false,
                                    colors: {
                                        discount: {
                                            textColor: "text-white",
                                            amountColor: "text-red-500",
                                            backgroundColor: "bg-red-500",
                                        },
                                        extraText: {
                                            textColor: "text-success",
                                        },
                                        outOfStock: {
                                            textColor: "text-white",
                                            backgroundColor: "bg-gray-400",
                                        },
                                    },
                                    favorite: {
                                        icon: (favouriteProducts === null || favouriteProducts === void 0 ? void 0 : favouriteProducts.find(function (x) { return x.pid === product.pid; })) ? pro_solid_svg_icons_1.faHeart : pro_light_svg_icons_1.faHeart !== null && pro_light_svg_icons_1.faHeart !== void 0 ? pro_light_svg_icons_1.faHeart : pro_light_svg_icons_1.faHeart,
                                        iconColor: "text-red-500",
                                        onClick: function () {
                                            if (user.uid) {
                                                (0, favourite_product_1.favorite_product)({
                                                    product_id: product._id,
                                                }).then(function (response) {
                                                    if (response.success) {
                                                        init();
                                                        dispatch((0, main_1.setMessageModal)({
                                                            title: "Success",
                                                            messages: ["".concat(response === null || response === void 0 ? void 0 : response.message)],
                                                        }));
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({
                                                            title: "Failed",
                                                            messages: ["".concat(response === null || response === void 0 ? void 0 : response.message)],
                                                        }));
                                                    }
                                                });
                                            }
                                            else {
                                                dispatch((0, main_1.setDrawerPage)("login"));
                                                dispatch((0, main_1.setDrawerOpen)(true));
                                            }
                                        },
                                    },
                                };
                            }));
                            setIsloadingProducts(false);
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({
                                title: "Something went wrong!",
                                messages: [response.message],
                            }));
                        }
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        fetchProducts();
    }, [currentPage, categoryId]);
    (0, react_1.useEffect)(function () {
        if (favouriteProducts && favouriteProducts.length > 0) {
            fetchProducts();
        }
    }, [favouriteProducts]);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(doshx_controls_web_1.ProductsBigControl, { currencySymbol: symbols_1.currencySymbol, category: {
                name: decodeURI(categoryName !== null && categoryName !== void 0 ? categoryName : "Unset"),
                color: "text-black",
            }, 
            // textColor="text-black"
            // amountColor=""
            // ratingColor=""
            products: products, onClick: function (product) {
                dispatch((0, selection_1.setSelectedProduct)(product));
                navigate("/product/".concat(product._id));
            }, pagination: {
                currentPage: currentPage,
                totalRecords: totalRecords,
                recordsPerPage: recordsPerPage,
                onClick: function (pageNumber) {
                    setCurrentPage(pageNumber);
                },
            }, canIncrement: true, button: {
                label: "Add to Cart",
                borderColor: "border-secondary",
                backgroundColor: "bg-secondary",
                textColor: "text-white",
                onClick: function (product) {
                    dispatch((0, add_product_thunk_1.add_product_thunk)(__assign(__assign({}, product), { quantity: 1 })));
                },
            } })));
};
exports.CategoryProductsPage = CategoryProductsPage;
